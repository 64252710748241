import { AnimatePresence, motion } from "framer-motion";
import { truthy } from "lib/types";
import Link from "next/link";
import { useEffect, useState } from "react";
import { useWindowScroll } from "react-use";
import { DesktopMenu } from "./desktop-menu";
import { Logo } from "./logo";
import { MobileMenu } from "./mobile-menu";
import { APPLE_APP_STORE, GOOGLE_PLAY_STORE } from 'lib/public-urls';
import { sendAnalyticsEvent, analyticsNames } from "lib/analytics";

type CTALink = {
  label: string;
  href: string;
  type: "cta";
  children?: never;
  onClick?: () => void;
};

type NavLink = {
  label: string;
  href: string;
  type: "nav";
  children?: never;
  onClick?: () => void;
};

type DropdownLink = {
  label: string;
  children: NavLink[];
  type?: never;
  href?: never;
};

export type NavbarLink = CTALink | NavLink | DropdownLink;

export interface NavbarProps {
  // For the home page, we want to show the logo in the navbar after scrolling.
  // For other pages, we want to show the logo all the time.
  variant?: "static" | "dynamic";
  showBackground?: boolean;
}

const LogoLink = (
  <Link href="/">
    <a className="focus:ring focus:outline-none items-center text-brand-blue w-20 absolute ml-auto mr-auto left-0 right-0 lg:static lg:flex lg:ml-0 lg:mr-0 lg:w-28">
      <Logo />
    </a>
  </Link>
);

// Magic number to determine when to show the logo in the navbar.
export const SCROLL_OFFSET_TRIGGER = 400;

export const scrollToCTA = () => {
  const scrollToEl = document?.querySelector("#get-started");
  const offset = 108;

  if (scrollToEl) {
    window.scrollTo({
      behavior: "smooth",
      top:
        scrollToEl.getBoundingClientRect().top -
        document.body.getBoundingClientRect().top -
        offset,
    });
  } else {
    // fallback
    window.location.hash = "#get-started";
  }
};

function DynamicLogo({ show }: { show: boolean }) {
  return (
    <AnimatePresence initial={false}>
      {show && (
        <motion.div
          exit={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          initial={{ opacity: 0 }}
        >
          {LogoLink}
        </motion.div>
      )}
    </AnimatePresence>
  );
}

const isMobileDevice = () => {
  if (typeof window !== 'undefined') {
    const userAgent = navigator.userAgent || navigator.vendor;
    return /iPad|iPhone|iPod|android/i.test(userAgent);
  } else {
    return false;
  }
};

export function Navbar(props: NavbarProps) {
  const { variant = "static", showBackground } = props;
  const { y } = useWindowScroll();
  let showLogo = y > SCROLL_OFFSET_TRIGGER;

  const [hasMounted, setHasMounted] = useState(false);

  const mobileDevice = isMobileDevice();

  const getAppDropdown = () => {
    return {
      label: "Get the app",
      children: [
        {
          label: "iOS",
          href: APPLE_APP_STORE,
          type: "nav",
          onClick: (e: React.MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault();
            window.location.href = APPLE_APP_STORE;
						sendAnalyticsEvent(analyticsNames.navbar_app_store_button)
          }
        },
        {
          label: "Android",
          href: GOOGLE_PLAY_STORE,
          type: "nav",
          onClick: (e: React.MouseEvent<HTMLAnchorElement>) => {
            e.preventDefault();
            window.location.href = GOOGLE_PLAY_STORE;
						sendAnalyticsEvent(analyticsNames.navbar_play_store_button)
          }
        },
      ],
    };
  };

  const getMobileAppStoreUrl = (): string => {
    const userAgent = navigator.userAgent || navigator.vendor;
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return APPLE_APP_STORE;
    } else if (/android/i.test(userAgent)) {
      return GOOGLE_PLAY_STORE;
    } else {
      return APPLE_APP_STORE;
    }
  };

  const getAppLink = () => {
    if (mobileDevice) {
      const appStoreUrl = getMobileAppStoreUrl();
      return {
        label: "Get the app",
        href: appStoreUrl,
        type: "nav",
        onClick: (e: React.MouseEvent<HTMLAnchorElement>) => {
          e.preventDefault();
          window.location.href = appStoreUrl;
        },
      };
    } else {
      return getAppDropdown();
    }
  };

  useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) {
    return null;
  }

  const links = [
    {
      label: "For Patients",
      href: "/",
      type: "nav",
    },
    {
      label: "For Clinics",
      href: "/clinics",
      type: "nav",
    },
    // {
    //   label: "About",
    //   href: "/about",
    //   type: "nav",
    // },
    {
      label: "Resources",
      children: [
        {
          label: "Articles",
          href: "/articles",
          type: "nav",
        },
        {
          label: "Glossary",
          href: "/glossary",
          type: "nav",
        },
      ],
    },
    mobileDevice ? {
      label: "Get the app",
      href: "",
      type: "nav",
      onClick: (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        const appStoreUrl = getMobileAppStoreUrl();
        window.location.href = appStoreUrl;
      },
    } : getAppDropdown(),
    {
      label: "Take the quiz",
      href: "/quiz",
      type: "nav",
			onClick: () => sendAnalyticsEvent(analyticsNames.navbar_take_quiz_button)
    },
  ].filter(truthy);

  return (
    <div
      className={`navbar fixed left-0 right-0 w-full z-10 transition-colors ${
        showLogo || showBackground
          ? `bg-white/75 backdrop-filter backdrop-blur-sm`
          : ""
      }`}
    >
      <div className="container">
        <div className="">
          <div className="flex lg:hidden items-center justify-between py-8">
            <MobileMenu links={links as NavbarLink[]} />
            {LogoLink}
          </div>
          <div className="hidden lg:flex items-center justify-between py-8">
            {variant === "static" ? (
              LogoLink
            ) : (
              <div className="flex-1 h-[2.5rem]">
                <DynamicLogo show={showLogo} />
              </div>
            )}
            <DesktopMenu links={links as NavbarLink[]} />
          </div>
        </div>
      </div>
    </div>
  );
}