import { APPLE_APP_STORE, GOOGLE_PLAY_STORE } from 'lib/public-urls';
import { v4 as uuid } from "uuid"

export const analyticsNames = {
	newsletter_signup: "website_newsletter_signup",
	app_download_email: "website_app_download_email",
	app_waitlist_signup: "website_app_waitlist_signup",
	app_calendly_scheduled: "website_app_calendly_scheduled",
	app_calendly_cancelled: "website_app_calendly_cancelled",
	app_calendly_rescheduled: "website_app_calendly_rescheduled",
	take_quiz_button: "website_take_quiz_button",
	get_app_button: "website_get_app_button",
	app_store_button: "website_app_store_button",
	play_store_button: "website_play_store_button",
	navbar_app_store_button: "website_navbar_app_store_button",
	navbar_play_store_button: "website_navbar_play_store_button",
	navbar_take_quiz_button: "website_navbar_take_quiz_button"

}

export const sendAnalyticsEvent = (eventName: string, extraData?: Record<string, any>) => {
  const eventId = uuid()

  if (window?.gtag) {
      window?.gtag("event", eventName)
  }
  if (window?.posthog) {
      window?.posthog.capture(eventName, { eventId: eventId, ...extraData })
  }
  if (window?.fbq) {
      window?.fbq(
          "trackCustom",
          eventName,
          { eventId: eventId },
          { eventId: eventId }
      )
  }
};